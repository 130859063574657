<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('proficiency_requests')"
				        :isFilter="false"/>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('proficiency_requests')"
				              :isFilter="false"/>
			</template>
			<div>
				<div class="row data-form" v-if="data!=null">
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('student_number')">
							{{ data.student_number }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('name_surname')">
							{{ data.name }} {{ data.surname }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('nationality')">
							{{ this.getLocaleText(data, 'nationality_code_name') }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('faculty')">
							{{ $i18n.locale == 'tr' ? data.faculty_name : data.faculty_name_en }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('department')">
							{{ $i18n.locale == 'tr' ? data.program_name : data.program_name_en }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('national_id_passport_no')">
							{{ data.national_id }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('email_address')">
							{{ data.email }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('mobile_number')">
							{{ data.mobile_tel }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('proficiency_certificate_type')">
							{{ this.getLocaleText(data, 'english_proficiency_certificate_type_name') }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('exam_date')">
							{{ data.english_proficiency_exam_date }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('exam_score')">
							{{ data.english_proficiency_exam_score }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" v-if="data.english_proficiency_certificate_type='toefl'">
						<b-form-group :label="$t('toefl_username')">
							{{ data.toefl_username }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" v-if="data.english_proficiency_certificate_type='toefl'">
						<b-form-group :label="$t('toefl_password')">
							{{ data.toefl_password }}
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" v-if="file!=null">
						<b-form-group :label="$t('proficiency_document')">
							<span class="mr-3 mb-1 d-inline-block">{{ file.file_name }}</span>
							<div class="d-inline-block">
								<a href="javascript:;" @click="getFile(file)">
									<i class="ri-download-2-line mr-1 top-plus-1"></i> {{ $t('download') }}
								</a>
							</div>
						</b-form-group>
					</div>
				</div>
				<div class="mb-3 d-inline-block"></div>
				<div v-if="data">
					<h6 class="text-uppercase mb-4">{{ $t('approval_procedures').toUpper() }}</h6>
					<ValidationObserver ref="form">
						<div class="row">
							<div class="col-12 col-md-6 col-lg-6 col-xl-3">
								<ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('status')">
										<b-form-select v-model="form.status" :options="options"></b-form-select>
										<div class="invalid-feedback" v-if="errors[0]" v-html="errors[0]"></div>
									</b-form-group>
								</ValidationProvider>
							</div>
						</div>
						<b-form-group>
							<b-button variant="primary" @click="updateStatus">{{ $t('update').toUpper() }}</b-button>
						</b-form-group>
					</ValidationObserver>
				</div>
			</div>
		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout";
	import Header from "@/layouts/AppLayout/Header";
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

	// Components
	import {ValidationProvider, ValidationObserver} from "vee-validate"

	// Services
	import RegistrationService from "@/services/RegistrationService";

	export default {
		components: {
			AppLayout,
			ValidationProvider,
			ValidationObserver,
		},
		data() {
			return {
				id: this.$route.params.id,
				data: null,
				file: null,
				options: [
					{text: this.$t('pending'), value: 'pending'},
					{text: this.$t('approved'), value: 'approved'},
					{text: this.$t('declined'), value: 'declined'},
				],
				form: {
					status: null,
				}
			}
		},
		metaInfo() {
			return {
				title: this.$t('proficiency_requests')
			}
		},
		created() {
			this.load();
		},
		methods: {
			load() {
				RegistrationService.get(this.id)
				                   .then(response => {
					                   this.data = response.data.data;
					                   this.form.status = this.data.english_proficiency_certificate_status;
					                   this.file = this.data.registration_files.find(item => {
						                   return item.code == 'language_proficiency';
					                   });
				                   })
			},
			getFile(file) {
				RegistrationService.downloadFile(file.uuid)
				                   .then(response => {
					                   const blob = new Blob([response.data], {type: file.mime_type})
					                   const data = window.URL.createObjectURL(blob);
					                   var link = document.createElement('a');
					                   link.href = data;
					                   link.download = file.file_name;
					                   link.click();
					                   setTimeout(function () {
						                   window.URL.revokeObjectURL(data);
					                   }, 100);
				                   })
				                   .catch(error => {
					                   if (error.status == 422) {
						                   this.$toast.error(error.data.errors.file[0]);
					                   }
					                   if (error.status == 406) {
						                   this.$toast.error(this.$t('api.' + error.data.message));
					                   }
				                   });
			},
			async updateStatus() {
				const isValid = await this.$refs.form.validate();
				if (isValid) {
					this.$swal
					    .fire({
						    title: this.$t('confirmation'),
						    text: this.$t('are_you_sure_you_want_to_update_the_approval_status'),
						    showCancelButton: true,
						    confirmButtonText: this.$t('yes'),
						    cancelButtonText: this.$t('no'),
					    })
					    .then((result) => {
						    if (result.isConfirmed) {
							    RegistrationService.putProficiency(this.data.id, this.form)
							                       .then(response => {
								                       this.$toast.success(this.$t('api.' + response.data.message));
							                       })
							                       .catch(error => {
								                       this.$toast.error(this.$t('api.' + error.data.message));
							                       });
						    }
					    })
				}
			}
		}
	}
</script>
